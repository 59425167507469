export const seo = {
  url: 'payments',
  title: {
    en: 'Omida Payments | Invoices | Documents | Settlements',
    es: 'Pagos Omida | Facturas | Documentos | Asentamientos',
    ro: 'Plăți Omida | Facturi | Documente | Așezări',
  },
  desc: {
    en: 'Fill out the form to contact our billing department. We respond to all inquiries in the order of applications. We kindly ask you not to resubmit a report for the same issue.',
    es: 'Rellene el formulario para ponerse en contacto con nuestro departamento de facturación. Respondemos a todas las consultas en el orden de las aplicaciones. Le pedimos amablemente que no vuelva a enviar un informe por el mismo problema.',
    ro: 'Completați formularul pentru a contacta departamentul nostru de facturare. Raspundem tuturor solicitarilor in ordinea aplicatiilor. Vă rugăm să nu retrimiteți un raport pentru aceeași problemă.',
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'tfl',
    'payments',
    'invoice',
    'document',
  ],
}

export const intro = {
  title: {
    en: 'Payments',
    es: 'Pagos',
    ro: 'Plăți',
  },
  desc: {
    en: 'Fill out the form to contact our billing department. We respond to all inquiries in the order of applications. We kindly ask you not to resubmit a report for the same issue.',
    es: 'Rellene el formulario para ponerse en contacto con nuestro departamento de facturación. Respondemos a todas las consultas en el orden de las aplicaciones. Le pedimos amablemente que no vuelva a enviar un informe por el mismo problema.',
    ro: 'Completați formularul pentru a contacta departamentul nostru de facturare. Raspundem tuturor solicitarilor in ordinea aplicatiilor. Vă rugăm să nu retrimiteți un raport pentru aceeași problemă.',
  },
}
